require("@rails/ujs").start()
//require("@rails/activestorage").start()

require("@rails/actiontext")

//require("moment/moment")
//require("../source/flickity.pkgd.min")
require("../source/jquery.maskedinput.min")
require("../source/app")


require("trix")