$(document).ready(function () {

    $('.image-gallery').flickity({
        cellAlign: 'left',
        contain: true,
        wrapAround: true,
        lazyLoad: 2,
        initialIndex: 1,
        prevNextButtons: false,
        pageDots: false
    });

    $(".phone-input").mask("+38(999)99-99-999");

})
